.Invoice_wrapper {

  max-width: 880px;
  width: 100%;
  padding: 0 10px;
  @media only screen and (min-width: 768px) {
    padding: 0;
  }

  .Invoice_logo {
    height: 22px;
  }

  .Invoice_title {
    color: #fff;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 25px;
    font-size: 16px;
    margin: 30px 0 0 0;
    @media only screen and (min-width: 768px) {
      font-size: 21px;
      margin: 50px 0 0 0;
    }
  }

  .Invoice_border {
    max-width: 880px;
    width: 100%;
    height: 1px;
    background: #2af3f3;
    opacity: 0.1;
    border-radius: 4px;
    margin: 10px 0 30px 0;
  }

  .Invoice_inner {
    max-width: 384px;
    width: 100%;
    margin: 0 auto;

    &__description {
      font-size: 12px;
      line-height: 14px;
      text-align: left;
      margin: 14px 0;
    }
  }

  .Invoice_merchant {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    @media only screen and (min-width: 768px) {
      font-size: 24px;
    }

    &::before {
      content: '';
      width: 16px;
      height: 18px;
      margin: 0 7px 0 0;
      background-image: url('../../../assets/img/invoice/merchant-coub.svg');
    }
  }

  .Invoice_inner__border {
    width: 100%;
    margin: 8px 0;
    height: 1px;
    background: #2af3f3;
    opacity: 0.1;
    border-radius: 4px;
    @media only screen and (min-width: 768px) {
      margin: 14px 0;
    }
  }

  .Invoice_inner__item {
    width: 100%;
    display: flex;
    justify-content: space-between;

    &-order {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-size: 18px;
      line-height: 22px;
      @media only screen and (min-width: 768px) {
        font-size: 24px;
        line-height: 28px;
      }
    }
  }

  .Invoice_inner__label {
    font-size: 10px;
    line-height: 13px;
    text-transform: uppercase;
    margin: 0 0 6px 0;
    @media only screen and (min-width: 768px) {
      font-size: 14px;
      line-height: 16px;
      margin: 0 0 10px 0;
    }
  }

  .Invoice_inner__input {
    width: 100%;

    .SelectWrapper {
      label {
        margin: 0 0 10px 0;
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
        @media only screen and (min-width: 768px) {
          font-size: 14px;
        }
      }
    }

    .v-menu__content {
      max-width: 100%;
      right: 0;
    }
  }
}

.Invoice_inner__checkbox {
  height: 22px;
  display: flex;
  align-items: center;
  margin: 0 0 14px 0;

  a {
    color: #2af3f3;
  }

  .Checkbox_root {
    display: flex;
    align-items: center;
  }

  .Checkbox_checkmark {
    // top: auto;
  }

  .Registration_checkbox-label {
    font-size: 12px;
    line-height: 14px;
    a {
      color: #2af3f3 !important;
    }
  }
}

.Invoice_countdown {
  display: flex;
  align-items: left;
  margin-top: 16px;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.04em;
  justify-content: center;
  flex-direction: column-reverse;
  text-align: left;
  @media only screen and (min-width: 768px) {
    text-align: center;
    align-items: center;
  }
  &Wrapper {
    display: flex;
    align-items: center;
    margin-top: 8px;
  }

  &__inner {
    color: #ff556d;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    margin: 0 8px 0 4px;
    display: flex;
    align-items: center;
    div {
      display: flex;
      align-items: center;
      span {
        &:nth-child(1) {
          display: none;
        }
      }
      i {
        margin: 0 2px 0 1px;
        &:nth-child(2), &:nth-child(8){
          display: none;
        }
      }
    }
  }
}